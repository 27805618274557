import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";

export function ModalSccss({ modalInfo, setModalInfo }) {
  const [openModal, setOpenModal] = useState(modalInfo?.state);

  return (
    <Modal
      show={openModal}
      size="md"
      onClose={() => {
        setOpenModal(false);
        setModalInfo({
          state: false,
        });
      }}
      popup
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <FaRegCheckCircle className="mx-auto mb-4 h-14 w-14 text-green-600 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {modalInfo?.successMessage}
          </h3>
        </div>
      </Modal.Body>
    </Modal>
  );
}
