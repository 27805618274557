import React from "react";
import { ConnectedProvider } from "./hooks/ConnectedContext";

export default function LayoutApp({ children }) {
  return (
    <ConnectedProvider>
        {children}
    </ConnectedProvider>
  );
}
