import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthCode from "./components/authCode/authCode";
import MatchesList from "./components/matchesList/MatchesList";
import LineUp from "./components/lineUp/LineUp";
import { Evaluation } from "./components/lineUp/evalutaion/Evaluation";
import LayoutApp from "./LayoutApp";
import LayoutComponent from "./components/LayoutComponent";
import FinalResult from "./components/lineUp/result/FinalResult";
 
function App() {
  return (
    <Routes>  
      <Route path="*" element={<Navigate to="/matches" />} />
      <Route path="/" element={<Navigate to="/matches" />} />
      <Route path="/authentication" element={<AuthCode />} />
      <Route
        path="/matches"
        element={
          <LayoutApp>
            <LayoutComponent>
              <MatchesList />
            </LayoutComponent>
          </LayoutApp>
        }
      />
      <Route
        path="/matches/:matcheSlug"
        element={
          <LayoutApp>
            <LayoutComponent>
              <LineUp />
            </LayoutComponent>
          </LayoutApp>
        }
      />
      <Route
        path="/matches/:matcheSlug/:playerSlug/evaluation"
        element={
          <LayoutApp>
            <LayoutComponent>
              <Evaluation />
            </LayoutComponent>
          </LayoutApp>
        }
      />
      <Route
        path="/matches/:matcheSlug/:playerSlug/result"
        element={
          <LayoutApp>
            <LayoutComponent>
              <FinalResult />
            </LayoutComponent>
          </LayoutApp>
        }
      />
    </Routes>
  );
}

export default App;
