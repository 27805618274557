function getQualities(ID) {
  switch (ID) {
    case "0":
      return {
        title: "Attaquant",
        labels: {
          techniques: "Qualités Techniques",
          tactiques: "Qualités Tactiques",
          physiques: "Qualités Physiques",
          mental: "Qualités Mental",
        },
        techniques: [
          "Passe Courte",
          "Contrôle de Balle",
          "1V1 - (Attaque)",
          "Capacité à garder le ballon dos au but",
          "Disponibilité dans le dos de défenseurs",
          "Finition (Efficacité dans la surface)",
          "Première Touche-(Contrôle Orienté)",
        ],
        tactiques: [
          "Verticalité",

          "Créativité",

          "Etre concient de l'espace et du timing",

          "Efficacité",

          "Appel en profondeur",

          "Anticipation",

          "Mouvement sans ballon",

          "Positionnement",
        ],
        mental: [
          "Prise de Décision",

          "Prise d'information",

          "Capacité à répéter les efforts",

          "Communication",
        ],
        physiques: [
          "Acceleration",

          "Agilité",

          "Force Naturel",

          "Vitesse",

          "coordination",
        ],
      };
    case "1":
      return {
        title: "Milieux",
        labels: {
          techniques: "Qualités Techniques",
          tactiques: "Qualités Tactiques",
          physiques: "Qualités Physiques",
          mental: "Qualités Mental",
        },
        techniques: [
          "Infiltration avec la Balle",

          "Première Touche",

          "Contrôle Orienté",

          "1V1-(Défensif)",

          "Passe Courte (Bonne Vitesse)",

          "1V1 - (Attaque)",

          "Passe qui casse des lignes",

          "Création d'occasion",

          "Être disponible entre les lignes",

          "Capacité de marquer",
        ],
        tactiques: [
          "Verticalité",

          "Créativité",

          "Etre concient de l'espace et du timing",

          "Efficacité",

          "Appel en profondeur",

          "Anticipation",

          "Mouvement sans ballon",

          "Positionnement",
        ],
        mental: [
          "Prise de Décision",

          "Prise d'information",

          "Capacité à répéter les efforts",

          "Communication",
        ],
        physiques: [
          "Acceleration",

          "Agilité",

          "Force Naturel",

          "Vitesse",

          "Coordination",
        ],
      };
    case "2":
      return {
        title: "Défenseurs",
        labels: {
          techniques: "Qualités Techniques",
          tactiques: "Qualités Tactiques",
          physiques: "Qualités Physiques",
          mental: "Qualités Mental",
        },
        techniques: [
          "Conduite avec la balle - jusqu'au bon moment",

          "Première Touche",

          "Controle Orienté",

          "Passe Courte (bonne Vitesse)",

          "Jouer en premiere intention",

          "Défense 1v1",

          "Interception",
        ],
        tactiques: [
          "Verticalité",

          "Créativité",

          "Etre concient de l'espace et du timing",

          "Efficacité",

          "Appel en profondeur",

          "Anticipation",

          "Mouvement sans ballon",

          "Positionnement B-",
        ],
        mental: [
          "Prise de Décision",

          "Prise d'information",

          "Capacité à répéter les efforts",

          "Communication",
        ],
        physiques: [
          "Acceleration",

          "Agilité",

          "Force Naturel",

          "Vitesse",

          "Coordination",
        ],
      };
    case "3":
      return {
        title: "Générale 5c5",
        labels: {
          techniques: "Qualités Techniques",
          tactiques: "",
          physiques: "",
          mental: "",
        },
        techniques: [
          "1v1",
          "Vitesse",
          "Coordination",
          "Équilibre",
          "Dribble",
          "Première touche (contrôle orienté)",
          "Qualités particulières",
          "Prise de décision",
          "Collecte d'informations",
        ],
        tactiques: [],
        physiques: [],
        mental: [],
      };
    case "4":
      return {
        title: "Gardien de But 8c8",
        labels: {
          techniques: "Qualités Techniques",
          tactiques: "Qualités Tactiques",
          physiques: "Qualités Physiques",
          mental: "Qualités Mental",
          b_plus: "B+",
          b_moins: "B-",
        },
        techniques: [],
        tactiques: [
          "Deplacement par rapport au ballon",
          "Deplacement par rapport a l equipe",
          "Communication",
        ],
        physiques: [
          "Morphologie",
          "Souplesse",
          "Coordination",
          "Vitesse / De reaction",
          "Vitesse / De deplacement",
        ],
        mental: ["Motivation", "Dynamique", "Leadership"],
        b_moins: [
          "Arret / Debout : Sol",
          "Arret / Debout : Mi haut",
          "Arret / Plongeon : Sol",
          "Arret / Plongeon : Mi haut",
          "Duel : Plongeon dans les pieds",
          "Duel : Reste sur ses appuis pour freiner l adversaire",
          "Passe profonde : Dans la surface",
          "Passe profonde : Hors de la surface",
          "Centre : Sol",
          "Centre : Mi haut",
        ],
        b_plus: [
          "Passing : Court",
          "Passing : Mi long",
          "Relance Mains : Court",
          "Relance Mains : Mi long",
          "Relance Pieds : Court",
          "Relance Pieds : Mi long",
          "Relance demi volee : Court",
          "Relance demi volee : Mi long",
        ],
      };
    case undefined:
      return {
        error: "returned undefined",
      };
    case null:
      return {
        error: "returned null",
      };
    default:
      return {
        error: "default retun no result",
      };
  }
}
module.exports = getQualities;
