import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export function ModalError({ modalInfo, setModalInfo }) {
  const [openModal, setOpenModal] = useState(modalInfo?.state);

  return (
    <Modal
      show={openModal}
      size="md"
      onClose={() => {
        setOpenModal(false);
        setModalInfo({
          state: false,
        });
      }}
      popup
    >
      <Modal.Header />
      <Modal.Body >
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {modalInfo?.errorMessage}
          </h3>
          <div className="flex justify-center gap-4">
            <Button
              color="failure"
              onClick={() => {
                setOpenModal(false);
                setModalInfo({
                  state: false,
                });
              }}
            >
              Ok, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
