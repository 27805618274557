import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/Axios";

export default function ResultPlayer({ playerInfo, matche_id }) {
  const { player_id, equipe_name, fullname, post, shirtNumber, img } =playerInfo;
  const navigate_to = useNavigate();
  const [error, setError] = useState({
    state: false,
    message: "",
  });

  async function getResultEvaluationData(data) {
    try {
      const response = await Axios.post("/evaluation-result", data);
      if (response?.status === 200) {
        const customUrl = fullname.split(" ")[0] + "-" + fullname.split(" ")[1];
        return navigate_to(customUrl.toLowerCase() + "/result", {
          state: { result: response?.data, playerId: player_id, withData:true,fullname:fullname ,img:img,shirtNumber:shirtNumber, post:post },
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return localStorage.removeItem("authUser");
      }
      if (error?.response?.status === 422 || error?.response?.status === 404) {
        const customUrl = fullname.split(" ")[0] + "-" + fullname.split(" ")[1];
        return navigate_to(customUrl.toLowerCase() + "/result", {
          state: { result: "", playerId: player_id, withData:false,fullname:fullname,img:img,shirtNumber:shirtNumber , post:post },
        });
      }
    }
  }

  const redirect_to = () => {
    const data = {
      id: player_id,
      match_id: matche_id,
    };
    getResultEvaluationData(data);
  };

  return (
    <>
      <tr
        key={player_id}
        className="bg-white border hover:bg-gray-100 border-gray-500 cursor-pointer"
        onClick={() => redirect_to()}
      >
        <th
          scope="row"
          className="flex items-center px-2 py-2 text-gray-900 whitespace-nowrap "
        >
          <img
            className="max-sm:w-8 max-sm:h-8 md:w-12 md:h-12 rounded-full"
            src={`${img}`}
            alt="player image"
          />
          <div className="ps-3">
            <div className="md:text-lg font-semibold max-sm:text-xs">
              {fullname?.toUpperCase()}
            </div>
          </div>
        </th>
        <td className="px-6 py-4 max-sm:text-center md:text-left font-bold md:text-sm max-sm:text-xs">
          {post ? post : "-"}
        </td>
        <td className="px-6 py-4 max-sm:text-center md:text-left font-bold md:text-lg max-sm:text-xs">
          {shirtNumber ? shirtNumber : "-"}
        </td>
      </tr>
    </>
  );
}
