import { Accordion } from "flowbite-react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import getQualities from "./qualities.js";
import { Button } from "flowbite-react";
import { FaSave } from "react-icons/fa";
import { ModalError } from "../../Modals/ModalErorr.js";
import { Label, Textarea } from "flowbite-react";
import Axios from "../../../api/Axios.js";
import { ModalSccss } from "../../Modals/ModalSccss.js";
import MaillotSvg from "../../Svgs/MaillotSvg.js";

export function Evaluation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { matcheSlug, playerSlug } = useParams();
  const [qualities, setQualities] = useState({});
  const [savedsuccess, setSavedsuccess] = useState({
    state: false,
    successMessage: "",
  });
  const [error, setError] = useState({
    state: false,
    errorMessage: "",
  });
  const [comment, setComment] = useState("");
  const [valuesTemplateArray, setValuesTemplateArray] = useState({
    techniques: [],
    mental: [],
    tactiques: [],
    physiques: [],
    b_plus: [],
    b_moins: [],
  });

  const handleEvaluationChange = (event, key, index) => {
    const value = event.target.value;

    setValuesTemplateArray((prevValues) => {
      const newValues = { ...prevValues };
      newValues[key][index] = value;
      return newValues;
    });
  };

  const filterPlayers = (post) => {
    let playerId;
    if (post === "G") {
      return (playerId = 4);
    }
    if (["AG", "DC", "AD", "D"].includes(post)) {
      return (playerId = 2);
    }
    if (["MDF", "MO", "MG", "MA", "MDR", "M"].includes(post)) {
      return (playerId = 1);
    }
    if (["A", "ALD", "AA", "ALG"].includes(post)) {
      return (playerId = 0);
    }
  };

  useEffect(() => {
    Object.keys(valuesTemplateArray).map((key) => {
      if (qualities[key]?.length) {
        let myArraylen = new Array(qualities[key]?.length).fill(null);
        return setValuesTemplateArray((prev) => {
          return {
            ...prev,
            [key]: myArraylen,
          };
        });
      }
    });
  }, [qualities]);

  useEffect(() => {
    if (!location.state || !location.state.post) {
      navigate("/matches");
      return;
    }
    document.title = `Evaluation ${
      playerSlug.split("-")[0] + " " + playerSlug.split("-")[1]
    }`;
    setQualities(getQualities(`${filterPlayers(`${location.state.post}`)}`));
  }, []);

  const handleSavingEvaluationData = () => {
    if (!comment || comment == "") {
      return setError({
        state: true,
        errorMessage:
          "Il serait utile que vous complétiez au moins un commentaire",
      });
    }

    const outPut = {
      commentaires: "",
      joueur_id: location.state.playerId,
      match_id: parseInt(matcheSlug.split("-")[3]),
      equipe_id: location.state.equipeId,
      qualite_technique: Object.values(valuesTemplateArray["techniques"]),
      qualite_tactique: Object.values(valuesTemplateArray["tactiques"]),
      qualite_physique: Object.values(valuesTemplateArray["physiques"]),
      qualite_mental: Object.values(valuesTemplateArray["mental"]),
      qualite_b_plus: Object.values(valuesTemplateArray["b_plus"]),
      qualite_b_moins: Object.values(valuesTemplateArray["b_moins"]),
      commentaires: comment,
    };

    Axios.post("/evaluation", outPut)
      .then((response) => {
        if (response.status == 200) {
          setComment('')
          setQualities({})
          setSavedsuccess({
            state: true,
            successMessage: "Évaluation enregistrée avec succès",
          });
          setTimeout(() => {
            navigate(-1);
            setSavedsuccess({
              state: false,
              successMessage: "",
            });
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error while saving evaluation data:", error);
      });
  };

  return (
    <div className="px-2">
      <div className="text-center p-4 font-semibold text-xl ">
        <h4 className="text-center">
        Dossier d'évaluation du joueur  <br />
          <div className="flex justify-center text-center py-5 items-center">
            <img
              className="max-sm:w-12 max-sm:h-12 md:w-16 md:h-16 rounded-full mx-1"
              src={location.state?.img}
            />
            <p className="font-semibold max-sm:text-sm mx-1">
              {" " + location.state?.fullname?.toUpperCase()}
            </p>
            <div>
              <MaillotSvg numMaillot={location.state?.shirtnumber} />
            </div>
          </div>
        </h4>
      </div>
      <Accordion collapseAll>
        {qualities.labels &&
          Object.keys(qualities.labels)?.map((key) =>
            qualities[key].map((lbl) => lbl).length > 0 ? (
              <Accordion.Panel>
                <Accordion.Title className="bg-green-700 hover:bg-green-600 text-white">
                  {qualities.labels[key]}
                </Accordion.Title>
                <Accordion.Content>
                  {qualities[key].map((lbl, index) => {
                    return (
                      <ul key={index}>
                        <li className="p-2">
                          <p className="text-sm font-semibold">{lbl}</p>
                          <div className="col-span-5 flex justify-between py-2">
                            {[0, 1, 2, 3, 4, 5]?.map((value) => (
                              <label className="flex items-center" key={value}>
                                <input
                                  type="radio"
                                  className="form-radio"
                                  name={lbl}
                                  value={value}
                                  onChange={(event) =>
                                    handleEvaluationChange(event, key, index)
                                  }
                                />
                                <span className="ml-2 text-sm">{value}</span>
                              </label>
                            ))}
                          </div>
                        </li>
                      </ul>
                    );
                  })}
                </Accordion.Content>
              </Accordion.Panel>
            ) : (
              <></>
            )
          )}
      </Accordion>
      <div className="max-w-md static py-4 px-5">
        <div className="mb-2 block">
          <Label htmlFor="comment" value="Commentaires" />
        </div>
        <Textarea
          id="comment"
          value={comment}
          placeholder="Laissez un commentaire..."
          required
          onChange={(e) => {
            setComment(e.target.value);
          }}
          rows={4}
        />
      </div>
      <div className="flex flex-row justify-end pt-2 mb-5 pb-5 px-4 ">
        <Button
          className="font-semibold h-10 "
          onClick={() => handleSavingEvaluationData()}
        >
          Sauvegarder
          <FaSave className="ml-2 h-5 w-5" />
        </Button>
      </div>
      {error.state && <ModalError modalInfo={error} setModalInfo={setError} />}
      {savedsuccess.state && (
        <ModalSccss modalInfo={savedsuccess} setModalInfo={setSavedsuccess} />
      )}
    </div>
  );
}
