import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Formation from "./playersClub/Formation";
import Result from "./result/Result";
import Axios from "../../api/Axios.js";

export default function LineUp() {
  const [Window, setWindow] = useState("evaluation");
  const { matcheSlug } = useParams();
  const [matcheInfo, setMatcheInfo] = useState();

  const displayComponent = () => {
    switch (Window) {
      case "result":
        return <Result matcheInfo={matcheInfo} matche_id={matcheSlug.split("-")[3]} />;
      case "evaluation":
        return <Formation matcheInfo={matcheInfo} />;
      default:
        return;
    }
  };
  const fetchSingleMatchData = async () => {
    try {
      const response = await Axios.get("/matchs/" + matcheSlug.split("-")[3]);
      setMatcheInfo(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
      }
    }
  };

  useEffect(() => {
    fetchSingleMatchData();
    document.title = "Joueurs";
  }, []);
  return (
    <div className="w-full ">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center justify-evenly text-gray-500 dark:text-gray-400">
          <li className="me-2" onClick={() => setWindow("evaluation")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className={`${
                Window === "evaluation"
                  ? "inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group"
                  : "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
              }`}
              aria-current="page"
            >
              <svg
                className={`${
                  Window === "evaluation"
                    ? "w-4 h-4 me-2 text-blue-600 dark:text-blue-500"
                    : "w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
              </svg>
              Évaluation
            </a>
          </li>
          <li className="me-2" onClick={() => setWindow("result")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className={`${
                Window === "result"
                  ? "inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group"
                  : "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
              }`}
            >
              <svg
                className={`${
                  Window === "result"
                    ? "w-4 h-4 me-2 text-blue-600 dark:text-blue-500"
                    : "w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
              </svg>
              Result
            </a>
          </li>
        </ul>
      </div>

      {displayComponent()}
    </div>
  );
}
