import React from "react";
import { GrNotes } from "react-icons/gr";
import { TbPlayFootball, TbShirtSport } from "react-icons/tb";
import ResultPlayer from "./ResultPlayer";
import PlayersNotFound from "../../codeErros/PlayersNotFound";

export default function ResultPlayers({ players,matche_id }) {
  return (
    <div className="my-5 relative overflow-x-auto shadow-md">
      {players.length > 0 ? (
        <table className="w-full text-sm text-left text-gray-500 border border-gray-500 md:text-center">
          <thead className="md:text-sm text-gray-700 uppercase bg-gray-50 max-sm:text-xs">
            <tr>
              <th scope="col"></th>
              <th scope="col" className="px-6 py-2">
                <TbPlayFootball size={22} />
              </th>
              <th scope="col" className="px-6 py-2 text-center">
                <TbShirtSport size={22} />
              </th>
            </tr>
          </thead>
          <tbody>
            {players?.map((player, index) => {
              return <ResultPlayer key={index} playerInfo={player} matche_id={matche_id} />;
            })}
          </tbody>
        </table>
      ) : (
        <PlayersNotFound />
      )}
    </div>
  );
}
