import React from "react";
import { useNavigate } from "react-router-dom";

export default function Player({ playerInfo, equipe_id }) {
  const {
    player_id,
    equipe_name,
    fullname,
    post,
    shirtNumber,
    img,
  } = playerInfo;
  const navigate_to = useNavigate();
  const redirect_to = () => {
    const customUrl = fullname.split(" ")[0] + "-" + fullname.split(" ")[1];
    return navigate_to(customUrl.toLowerCase() + "/evaluation", {
      state: { post: post,img:img, playerId: player_id,fullname:fullname, equipeId: equipe_id ,shirtnumber:shirtNumber},
    });
  };

  return (
    <tr
      key={player_id}
      className="bg-white border hover:bg-gray-100 border-gray-500 cursor-pointer"
      onClick={() => redirect_to()}
    >
      <th
        scope="row"
        className="flex items-center px-2 py-2 text-gray-900 whitespace-nowrap "
      >
        <img
          className="max-sm:w-8 max-sm:h-8 md:w-12 md:h-12 rounded-full"
          src={`${img}`}
          alt="player image"
        />
        <div className="ps-3">
          <div className="md:text-lg font-semibold max-sm:text-xs">
            {fullname?.toUpperCase()}
          </div>
        </div>
      </th>
      <td className="px-6 py-4 max-sm:text-center md:text-left font-bold md:text-sm max-sm:text-xs">
        {post ? post: "-"}
      </td>
      <td className="px-6 py-4 max-sm:text-center md:text-left font-bold md:text-lg max-sm:text-xs">
        {shirtNumber?shirtNumber:"-"}
      </td>
    </tr>
  );
}
