import React, { useContext } from "react";
import { ConnectedContext, ConnectedProvider } from "../hooks/ConnectedContext";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function LayoutComponent({ children }) {
  const { userConnected } = useContext(ConnectedContext);
  const navigate = useNavigate();

  return (
    <ConnectedProvider>
      <div className="flex fixed w-full top-0 overflow-hidden z-50 justify-between items-center border-2 bg-green-700 max-sm:px-3 px-10 py-3.5 text-sm font-semibold leading-6 text-white shadow-sm">
        <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
          <IoChevronBackCircle className="h-10 w-10" />
          <p className="text-sm font-normal">Retour</p>
        </div>
        <p>
          Bonjour{" "}
          {userConnected?.nom.toUpperCase() +
            " " +
            userConnected?.prenom?.toUpperCase()}
        </p>
      </div>
      <div className="lg:px-2 max-sm:px-0 mt-20 overflow-hidden">{children}</div>
    </ConnectedProvider>
  );
}
