import { Accordion, Card, Rating, Toast } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getQualities from "../evalutaion/qualities";
import MaillotSvg from "../../Svgs/MaillotSvg";
import { TbDeviceIpadHorizontalExclamation } from "react-icons/tb";

export default function FinalResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const [qualities, setQualities] = useState({});
  const [result, setResult] = useState(null);

  const filterPlayers = (post) => {
    let playerId;
    if (post === "G") {
      return (playerId = 4);
    }
    if (["AG", "DC", "AD", "D"].includes(post)) {
      return (playerId = 2);
    }
    if (["MDF", "MO", "MG", "MA", "MDR", "M"].includes(post)) {
      return (playerId = 1);
    }
    if (["A", "ALD", "AA", "ALG"].includes(post)) {
      return (playerId = 0);
    }
  };

  useEffect(() => {
    if (!location.state || location.state.withData === null) {
      navigate("/matches");
      return;
    }
    location.state.result && setResult(location?.state.result);
    location.state &&
      setQualities(getQualities(`${filterPlayers(`${location?.state.post}`)}`));
  }, []);

  return (
    <div className="px-2">
      <div className="text-center p-4 font-semibold text-xl ">
        <h4 className="text-center">
          Résultat de l'évaluation du joueur <br />
          <div className="flex justify-center text-center py-5 items-center">
            <img
              className="max-sm:w-12 max-sm:h-12 md:w-16 md:h-16 rounded-full mx-1"
              src={location.state?.img}
            />
            <p className="font-semibold max-sm:text-sm mx-1">
              {" " + location.state?.fullname?.toUpperCase()}
            </p>
            <div>
              <MaillotSvg numMaillot={location.state?.shirtNumber} />
            </div>
          </div>
        </h4>
      </div>
      {location.state && location.state.withData === true ? (
        <>
          <Accordion collapseAll className="mb-2">
            {qualities.labels &&
              Object.keys(qualities.labels).map((key) => {
                const keyQualities = qualities[key];
                const keyResults =
                  result && result[`qualites_${key}`]
                    ? JSON.parse(result[`qualites_${key}`])
                    : [];
                if (keyQualities && keyQualities.length > 0) {
                  return (
                    <Accordion.Panel key={key}>
                      <Accordion.Title className="bg-green-700 hover:bg-green-600 text-white">
                        {qualities.labels[key]}
                      </Accordion.Title>
                      <Accordion.Content>
                        {keyQualities.map((lbl, index) => (
                          <ul key={index}>
                            <li className="p-2">
                              <p className="text-sm font-semibold">{lbl}</p>
                              <Rating className="p-2">
                                {Array.from(
                                  {
                                    length:
                                      parseInt(keyResults[index], 10) || 0,
                                  },
                                  (_, i) => (
                                    <Rating.Star key={i} filled={true} />
                                  )
                                )}
                                {Array.from(
                                  {
                                    length:
                                      5 -
                                      (parseInt(keyResults[index], 10) || 0),
                                  },
                                  (_, i) => (
                                    <Rating.Star key={i} filled={false} />
                                  )
                                )}
                              </Rating>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Content>
                    </Accordion.Panel>
                  );
                } else {
                  return <></>;
                }
              })}
          </Accordion>
          <Card className="max-w-full max-sm:max-w-md mb-10">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
              Commentaires
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400 p-2">
              {result?.commentaires
                ? result?.commentaires
                : "Aucun commentaire "}
            </p>
          </Card>
        </>
      ) : (
        <div className="w-full flex justify-center">
          <Toast className="w-full bg-orange-400">
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
              <TbDeviceIpadHorizontalExclamation className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm text-black font-normal">
              Aucun résultat pour ce joueur, pouvez-vous évaluer le joueur
            </div>
          </Toast>
        </div>
      )}
    </div>
  );
}
