import React, { useState, useEffect } from "react";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import Axios from "../../api/Axios";

export default function AuthCode() {
  const [code, setCode] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate_to = useNavigate();

  const getJwtTokenFromCookie = () => {
    const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
      const [name, value] = cookie.trim().split('=').map(decodeURIComponent);
      return { ...cookies, [name]: value };
    }, {});
    return cookies.jwt_code;
  };
  

  const Login = async (pass) => {
    try {
      const jwtToken = getJwtTokenFromCookie();
 
      console.log(document.cookie);
      console.log(jwtToken);
      const response = await Axios.post(
        "/login",
        { pass },
        { withCredentials: true }
      );
      if (response?.status === 200) {
        localStorage.setItem("authUser",JSON.stringify(response?.data?.user))
        navigate_to("/matches");
        return; 
      }
    } catch (error) {
      setLoading(false);
      return setError(error?.response?.data?.message);
    }
  };

  const validationCode = () => {
    setLoading(true);
    if (code.length > 8 || code.length === 0) {
      setLoading(false);
      return setError("Votre mot de passe incorrect");
    }
    Login(code);
  };

  useEffect(() => {
    document.title = "Authentication";
    if (localStorage?.getItem('authUser')) {
      return navigate_to('/matches');
    }
  }, []);

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src={"./images/Logo_Federation_Royale_Marocaine_Football.png"}
          alt="Logo Federation Royale Marocaine Football"
        />
        <h2 className="mt-5 text-center text-2xl font-medium leading-9 tracking-tight text-gray-900">
          Entrez votre code
        </h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mt-2 mb-4">
          <input
            id="code"
            type="text"
            placeholder="********"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onFocus={() => setError("")}
            required
            disabled={loading}
            className={`block w-full p-2 h-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
              error ? "ring-red-700" : "ring-gray-400"
            } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 focus-visible:outline-green-600`}
          />
        </div>
        {error && (
          <div className="mt-2 mb-4 text-sm text-orange-700	text-center">
            <p>{error}</p>
          </div>
        )}

        <button
          onClick={validationCode}
          disabled={loading}
          className="flex w-full justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          {loading ? (
            <>
              <Spinner aria-label="Spinner button example" size="sm" />
              <span className="pl-3">Chargement...</span>
            </>
          ) : (
            "Se connecter"
          )}
        </button>
      </div>
    </div>
  );
}