import React, { useState } from "react";
import Players from "./Players";

export default function Formation({ matcheInfo }) {
  const [clubSelected, setClubSelected] = useState("equipea");

  const displayPlayerListByClubSelected = () => {
    if (clubSelected === "equipea") {
      return <Players players={matcheInfo.playersA} equipe_id={matcheInfo.equipe_id} />;
    }
    return <Players players={matcheInfo.playersB} equipe_id={matcheInfo.equipe_id} />;
  };

  return (
    matcheInfo && (
      <div className="w-full py-2 ">
        <div className="flex justify-evenly">
        <div className="px-1">
            <button
              type="button"
              onClick={() => setClubSelected("equipea")}
              className={`text-white rounded-md w-100 max-sm:w-45 flex items-center ${
                clubSelected === "equipea" ? "bg-red-700" : " bg-red-400"
              } font-semibold text-sm px-5 py-1 text-center`}
            >
              <img src={matcheInfo.equipe_logo} alt="equipe image" width={40} />
              <span className=" max-h-16 overflow-hidden">
                {matcheInfo?.equipe_name}
              </span>
            </button>
          </div>
          <div className="px-1">
            <button
              type="button"
              onClick={() => setClubSelected("equipeb")}
              className={`text-white rounded-md w-100 max-sm:w-45 flex items-center ${
                clubSelected === "equipeb" ? "bg-red-700" : " bg-red-400"
              } font-semibold text-sm px-5 py-1 text-center`}
            >
              <img src={matcheInfo.adversaire_logo} alt="equipe image" width={40} />
              <span className=" max-h-16 overflow-hidden">
                {matcheInfo.adversaire_name}
              </span>
            </button>
          </div>
        </div>
        {displayPlayerListByClubSelected()}
      </div>
    )
  );
}
