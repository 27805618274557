import React from "react";
import "./Matche.css";
import { useNavigate } from "react-router-dom";

export default function Matche({ matcheinfo }) {
  const { id, equipea, equipeb, match_time,match_date, stade } = matcheinfo;
  const navigate_to = useNavigate();
  const redirect = () => {
    const firstClub = equipea.name.split(" ")[0] + equipea.name.split(" ")[1];
    const secondClub = equipeb.name.split(" ")[0] + equipea.name.split(" ")[1];
    return navigate_to(
      `/matches/${firstClub.toLowerCase()}-vs-${secondClub.toLowerCase()}-${id}`
    );
  };

  return (
    <div className="match-item" onClick={redirect}>
      <div className="team-info">
        <p className="team-name">{equipea.name}</p>
        <img
          src={equipea.logo}
          className="team-logo"
          alt={equipea.name + " logo"}
        />
      </div>
      <div className="match-details">
        <p className="match-time">{match_time}</p>
        <p className="match-date">{match_date.split('-')[1] +"/"+ match_date.split('-')[2]}</p>
        <p className="stadium">{stade}</p>
      </div>
      <div className="team-info">
        <img
          src={equipeb.logo}
          className="team-logo"
          alt={equipeb.name + " logo"}
        />
        <p className="team-name">{equipeb.name}</p>
      </div>
    </div>
  );
}
