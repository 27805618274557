import React, { useState, useEffect } from "react";
import Matche from "./Matche.js";
import Axios from "../../api/Axios.js";
import MatchesNotFound from "../codeErros/MatchesNotFound.js";
import { Spinner } from "flowbite-react";

export default function MatchesList() {
  const [dates, setDates] = useState([]);
  const [matches, setMatches] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");

  async function fetchMatchesByDate(date) {
    try {
      setLoading(true);
      const response = await Axios.post("/matchs", { date });
      setMatches((prevMatches) => ({
        ...prevMatches,
        [date]: response.data,
      }));
      response.data && setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchDates() {
      try {
        setLoading(true);
        const response = await Axios.get("/matchs-dates");
        const matchDates = response.data.map((match) => match.match_date);
        setDates(matchDates);
        setSelectedDate(matchDates[0]);
        fetchMatchesByDate(matchDates[0]);
        response.data && setLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("authUser");
        }
      }
    }

    fetchDates();
  }, []);

  const renderMatches = (matches) => {
    return matches?.length > 0 ? (
      matches.map((matche, index) => <Matche key={index} matcheinfo={matche} />)
    ) : (
      <MatchesNotFound />
    );
  };

  const handleTabChange = async (date) => {
    setSelectedDate(date);
    if (!matches[date]) {
      await fetchMatchesByDate(date);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner aria-label="Center-aligned spinner example" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="flex justify-center pt-4 font-semibold text-xl ">
        <h4>List des matches</h4>
      </div>
      <div className="flex min-h-full min-w-full flex-col justify-center px-2 pb-12 pt-3 lg:px-8">
        <div className="overflow-x-auto">
          <div className="flex items-center w-full justify-center my-5">
            {dates.map((date, index) => (
              <div key={index} className="cursor-pointer m-3 max-sm:m-1">
                <label
                  htmlFor={`tab-${index}`}
                  className={`cursor-pointer px-5 max-sm:py-4 py-5  rounded-md ${
                    selectedDate === date ? "bg-teal-800 text-white" : "bg-gray-400 text-white"
                  }`}
                  onClick={() => {
                    handleTabChange(date);
                  }}
                >
                  {date}
                </label>
              </div>
            ))}
          </div>
          <div>
            {matches[selectedDate] ? (
              renderMatches(matches[selectedDate])
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
