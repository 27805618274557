import axios from "axios";


const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/competitions-ligue`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
});
export default Axios;