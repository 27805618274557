import { jwtDecode } from "jwt-decode";
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ConnectedContext = createContext(false);

const ConnectedProvider = ({ children }) => {
  const [connected, setConnected] = useState();
  const [userConnected, setUserConnected] = useState();

  const navigate_to = useNavigate();

  const getDecodedAccessToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("authUser");
    !isAuthenticated && navigate_to("/authentication");
    setConnected(isAuthenticated);
    // isAuthenticated && setUserConnected(getDecodedAccessToken(isAuthenticated));
    isAuthenticated && setUserConnected(JSON?.parse(isAuthenticated));
  }, []);

  return (
    connected && (
      <ConnectedContext.Provider value={{ userConnected }}>
        {children}
      </ConnectedContext.Provider>
    )
  );
};

export { ConnectedContext, ConnectedProvider };
