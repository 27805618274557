import React from "react";
import Player from "./Player";
import { TbShirtSport } from "react-icons/tb";
import { TbPlayFootball } from "react-icons/tb";
import { GrNotes } from "react-icons/gr";
import PlayersNotFound from "../../codeErros/PlayersNotFound";

export default function Players({players,equipe_id}) {

  return (
    <div className="my-5 relative overflow-x-scroll shadow-md">
      {players?.length > 0 ? (
        <table className="w-full text-sm text-left text-gray-500 border border-gray-500 md:text-center">
          <thead className="md:text-sm text-gray-700 uppercase bg-gray-50 max-sm:text-xs">
            <tr>
              <th scope="col"></th>
              <th scope="col" className="px-6 py-2">
                <TbPlayFootball size={22} />
              </th>
              <th scope="col" className="px-6 py-2 text-center">
                <TbShirtSport size={22} />
              </th>
            </tr>
          </thead>
          <tbody>
            {players &&
              players?.map((player, index) => {
                return <Player key={index} playerInfo={player} equipe_id={equipe_id} />;
              })}
          </tbody>
        </table>
      ) : (
        <PlayersNotFound />
      )}
    </div>
  );
}
